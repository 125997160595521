$gutter: 1rem; //for primeflex grid system

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "../node_modules/@fullcalendar/core/main.min.css";
@import "../node_modules/@fullcalendar/daygrid/main.min.css";
@import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/demo/flags/flags.css";
@import '~bootstrap/scss/bootstrap';

fieldset.p-fieldset {
  padding: revert;
  margin: revert;
}

.p-fieldset .p-fieldset-legend {
  width: auto;
}

.iam-scrollable-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.iam-scrollable-y::-webkit-scrollbar {
  width: 8px;
}

.iam-scrollable-y::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
}

.p-editable-column, .quick-editable-column {
  background-color: #e4f1ff;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td.action-column {
  padding: 0 !important;
}

.login-body {
  background: url(./assets/layout/images/whychain-bg.jpeg) center no-repeat;
  background-size: cover;
}